import { FormType } from '@/constant/form';
import { DateTime } from '@/constant/tableConfig';
import {
  SORTING_LINE, DEVICE_TYPE, STATUS, SORTING_LINE_ENUM, DEVICE_TYPE_ENUM, STATUS_ENUM,
} from './constant';

export const TABLECOLUMN = (exceptionTypeCode) => [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '上报时间',
  prop: 'createTime',
  ...DateTime,
}, {
  label: '分拣线',
  prop: 'sortingLine',
  width: 180,
  formatter: (row) => SORTING_LINE_ENUM[row.sortingLine],
}, {
  label: '设备类型',
  prop: 'deviceType',
  width: 180,
  formatter: (row) => DEVICE_TYPE_ENUM[row.sortingLine],
}, {
  label: '设备地址',
  prop: 'deviceAddress',
  width: 180,
}, {
  label: '故障类型',
  prop: 'exceptionType',
  width: 180,
  formatter: (row) => exceptionTypeCode.reduce((total, item) => (
    { ...total, [item.value]: item.label }), {})[row.sortingLine],
}, {
  label: '恢复状态',
  prop: 'status',
  width: 180,
  formatter: (row) => STATUS_ENUM[row.sortingLine],
}, {
  label: '故障说明',
  prop: 'description',
  width: 180,
}];
export function serchFields(exceptionType) {
  return [
    {
      label: '分拣线',
      prop: 'sortingLine',
      component: FormType.SELECT,
      options: SORTING_LINE,
    }, {
      label: '设备类型',
      prop: 'deviceType',
      component: FormType.SELECT,
      options: DEVICE_TYPE,
    }, {
      label: '故障类型',
      prop: 'exceptionType',
      component: FormType.SELECT,
      options: exceptionType,
    }, {
      label: '恢复状态',
      prop: 'status',
      component: FormType.SELECT,
      options: STATUS,
    }, {
      label: '上报时间',
      prop: 'createTime',
      component: FormType.DATE_PICKER,
      componentAttrs: {
        type: 'daterange',
        default: [],
      },
    },
  ];
}
