<template>
  <pl-block>
    <PlForm
      v-model="formData"
      mode="search"
      :fields="serchFields(EXCEPTION_TYPE_CODE)"
      @submit="handleQueryFrom"
      @reset="handleQueryFrom"
    />
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      v-loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN(EXCEPTION_TYPE_CODE)"
      @update:pagination="handlePaginationChange"
    >
      <template #receiptNo="{row}">
        <el-button
          type="text"
          @click="go('/in-warehouse/inbound-order/detail',row)"
        >
          {{ row.receiptNo }}
        </el-button>
      </template>
    </PlTable>
  </pl-block>
</template>
<script>
import loadingMixin from '@thales/loading';
import moment from 'moment';
import { TABLECOLUMN, serchFields } from './fileds';
import mixin from './mixin';
import { autoDeviceException } from './api';

export default {
  name: 'DeviceExceptionCenter',
  filters: {},
  mixins: [loadingMixin, mixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      tableData: [],
      formData: {},
      loading: {
        getTableData: false,
      },
    };
  },
  computed: {
    EXCEPTION_TYPE_CODE() {
      return this.$dictionaryFiledMap.EXCEPTION_TYPE_CODE || [];
    },
  },
  created() {
    this.getTableData();
  },
  methods: {
    handleQueryFrom(data) {
      this.pagination.page = 1;
      const formData = data;
      // 调用表单重置 时间控件会跳到 1970 年，增加default 解决问题后 导致 重置 会携带默认值，所以在这里判定，只有一个值的时候即为重置
      const createTime = !(formData.createTime && formData.createTime.length)
        || (formData.createTime
          && formData.createTime instanceof Array
          && formData.createTime.length < 2);
      if (createTime) {
        formData.createTime = [];
      }
      this.formData = {
        ...formData,
        startTime:
          formData.createTime
          && formData.createTime[0]
          && moment(formData.createTime[0])
            .startOf('day')
            .valueOf(),
        endTime:
          formData.createTime
          && formData.createTime[1]
          && moment(formData.createTime[1])
            .endOf('day')
            .valueOf(),
        createTime: '',
      };
      this.getTableData();
    },
    handlePaginationChange() {
      this.getTableData();
    },
    async getTableData() {
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await autoDeviceException(pagination, this.formData);
      this.tableData = resp.records;
      this.pagination.total = resp.total;
    },
  },
};
</script>
