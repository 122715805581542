import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description: 分拣设备异常 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/424/interface/api/11996
 */

export function autoDeviceException(params, data) {
  return wmsPlusHttp.post('/warehouse_automation/fluent_device_exception/page', data, {
    params,
  });
}
/**
 * @description: 获取仓库详情
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11186
 */

export function warehouseGet(params) {
  return wmsPlusHttp.get('/warehouse_foundation/warehouse/get', params);
}
