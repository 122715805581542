export const SORTING_LINE = [{
  label: '1号分拣线',
  value: 'NO1',
}, {
  label: '2号分拣线',
  value: 'NO2',
}];
export const SORTING_LINE_ENUM = SORTING_LINE
  .reduce((total, item) => ({ ...total, [item.value]: item.label }), {});

export const DEVICE_TYPE = [{
  label: '电子标签',
  value: 'DPS',
}, {
  label: '传输线',
  value: 'PLTCYER',
}];
export const DEVICE_TYPE_ENUM = DEVICE_TYPE
  .reduce((total, item) => ({ ...total, [item.value]: item.label }), {});

export const STATUS = [{
  label: '已恢复',
  value: 'NORMAL',
}, {
  label: '未恢复',
  value: 'UN_NORMAL',
}];

export const STATUS_ENUM = STATUS
  .reduce((total, item) => ({ ...total, [item.value]: item.label }), {});
